.alert {
    border-radius: 4px;
    background: $cachet_yellow;
    border: solid 2px $cachet_dark-yellow;

    &.alert-success {
        background-color: $cachet_green;
        border: solid 2px $cachet_dark-green;
        color: white;

        a {
            color: white;
            font-weight: bold;
        }
    }

    &.alert-info {
        background: $cachet_blue;
        border: solid 2px $cachet_dark-blue;
        color: white;

        a {
            color: white;
            font-weight: bold;
        }
    }

    &.alert-danger {
        background: $cachet_red;
        border: solid 2px $cachet_dark-red;
        color: white;

        a {
            color: white;
            font-weight: bold;
        }
    }
}
