//
// Pager pagination
// --------------------------------------------------


.pager {
    li {
        > a, > span {
            border-radius: 3px;
        }
    }
}
