body.status-page {
    padding-top: 40px;
    font-family: $base-font-family;
    background-color: #F0F3F4;
    color: #333333;
    font-size: 1.4em;
    font-weight: $base-font-weight;
    -webkit-font-smoothing: antialiased;

    &.no-padding {
        padding-top: 0 !important;
    }

    hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    h1, h2, h3, h4, h5 {
        margin-bottom: 20px;
    }

    img {
        box-sizing: border-box;

        &.emoji {
            height: 20px;
            width: 20px;
            vertical-align: middle;
            max-width: none;
        }
    }

    p, strong {
        color: $cachet-base-dark;
    }

    .tooltip {
        .tooltip-inner {
            padding: 8px 12px;
            font-size: 14px;
            word-wrap: break-word;
        }
    }

    .help-icon {
        cursor: help;
    }

    .text-success, .text-component-1 {
        color: $cachet_green;
    }

    .text-info, .text-component-2 {
        color: $cachet_blue;
    }

    .text-alert, .text-component-3 {
        color: $cachet_yellow;
    }

    .text-danger, .text-component-4 {
        color: $cachet_red;
    }

    .container {
        max-width: 960px;
    }

    .page-header {
        margin-top: 10px;
    }

    .app-banner {
        margin-bottom: 30px;
    }

    .about-app {
        margin-bottom: 40px;
        p {
            font-size: 1.2em;
        }
    }

    .alert {
        font-size: 1.2em;
        font-weight: 600;
    }

    .timeline {
        .content-wrapper {
            margin-top: 40px;
            margin-bottom: 40px;
        }
        h3 {
            margin-top: 30px;
            margin-bottom: 40px;
            font-size: 22px;
            small {
                margin-left: 15px;
            }
        }

        .panel {
            .panel-body {
                h1 {
                    margin-top: 0;
                    margin-bottom: 4px;
                    font-size: 2em;
                }
                h2 {
                    margin-top: 0;
                    margin-bottom: 4px;
                    font-size: 1.8em;
                }
                h3 {
                    margin-top: 0;
                    margin-bottom: 4px;
                    font-size: 1.6em;
                }
                h4 {
                    margin-top: 0;
                    margin-bottom: 4px;
                    font-size: 1.4em;
                }
                h5 {
                    margin-top: 0;
                    margin-bottom: 4px;
                    font-size: 1.2em;
                }
                p {
                    margin: 0;
                }
            }
        }

        .moment {
            width: 100%;
            padding-bottom: 10px;
            position: relative;
            &.first {
                &:before {
                    // height: 130%;
                    top: -5px;
                }
            }
            &:before {
                content: '';
                position: absolute;
                left: 26px;
                top: -5px;
                width: 2px;
                height: 100%;
                background: #7266BA;
            }
            .status-icon {
                background: #fff;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                border: 1px solid #e8e8e8;
                position: absolute;
                left: 25px;
                top: 14px;
                .icon {
                    position: absolute;
                    top: 7px;
                    left: 11px;

                    &.ion-alert {
                        left: 15px;
                    }
                }
                &.status-0 {
                    color: $cachet_pink;
                }
                &.status-1 {
                    color: $cachet_orange;
                }
                &.status-2 {
                    color: $cachet_yellow;
                }
                &.status-3 {
                    color: $cachet_blue;
                }
                &.status-4 {
                    color: $cachet_green;
                }
            }
            &.last:before {
                background: #fff;
            }
            .panel {
                margin: 0;
                border-radius: 2px;

                &.panel-message {
                    border: 1px solid #e8e8e8;
                    .date {
                        color: darken(#c7c7c7, 20%);
                    }

                    &:before {
                        position: absolute;
                        top: 16px;
                        left: 1px;
                        display: inline-block;
                        border-top: 15px solid transparent;
                        border-left: 0 solid #e8e8e8;
                        border-right: 15px solid #e8e8e8;
                        border-bottom: 15px solid transparent;
                        content: " ";
                    }

                    &:after {
                        position: absolute;
                        top: 17px;
                        left: 2px;
                        display: inline-block;
                        border-top: 14px solid transparent;
                        border-left: 0 solid #fff;
                        border-right: 14px solid #fff;
                        border-bottom: 14px solid transparent;
                        content: " ";
                    }
                }

                .panel-heading {
                    strong {
                        font-size: 1.1em;
                    }
                }

                .panel-body {
                    border-top: 1px solid #eee;
                    p {
                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }
                        font-size: 1.1em;
                    }
                }
            }
        }
    }
    @media (max-width: 767px) {
        .timeline .moment .content {
            width: 100%;
        }
    }

    .list-group {
        // margin-bottom: 20px;
        padding-left: 0;
        // border-radius: 0;

        .list-group-item {
            border-radius: 0;
            background-color: #ffffff;
            border: 1px solid $cachet_gray_light;
            font-size: 1.1em;
            padding: 15px 15px;

            a {
                font-weight: 400;
            }

            h4 {
                margin: 0;
                font-weight: 400;
                max-width: 90%;
            }

            p, time {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }

            time {
                margin-bottom: 0;
            }

            i.icon {
                font-size: 21px;
                line-height: 24px;
                text-align: center;
                display: inline-block;
                min-width: 20px;
            }

            &.group-name {
                background-color: $cachet_gray_light;
                padding: {
                    top: 0.6em;
                    bottom: 0.6em;
                }
                cursor: pointer;
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            &.break {
                padding: 1px;
                background-color: $cachet-base-medium;
            }
        }

        &.components {
            @extend .panel;
            border-color: $cachet_gray_light;

            &:last-child {
                margin-bottom: 30px;
            }

            + .components {
                margin-top: 5px;
            }

            p {
                margin-bottom: 10px;
            }
            .badge {
                color: transparent;
            }

            a {
                color: $cachet-base-dark !important;
            }

        }
    }

    footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 60px;
        padding: 30px 0 60px 0;
        border-top: 1px solid $cachet_gray_light;
        color: $cachet-gray-darker;
        font-size: 13px;
        line-height: 30px;
        text-align: center;
        text-shadow: 0 1px 0 rgba(255,255,255,0.5);

        .btn {
            text-shadow: none;
        }

        .icons {
            a.icon-link {
                display: inline-block;
                min-width: 30px;
                height: 30px;
                border-radius: 3px;
                background-color: $cachet-gray-darker;
                text-align: center;
                color: $cachet_gray_light;
                transition: all 0.15s;
                padding: 0 10px 0 10px;

                &.rss {
                    background-color: $cachet-orange;
                }

                &:hover {
                    text-decoration: none;
                    background-color: darken($cachet-gray-darker, 10%);
                }
            }
        }

        .list-inline > li {
            padding-right: 0;
        }

        @media (min-width: 768px) {
            text-align: left;

            .list-inline {
                text-align: right;
                > li {
                    padding-right: 0;
                }
            }

            .icons {
                margin-top: 0;
                text-align: right;
            }
        }
    }

    // Individual section styling.
    .section-timeline, .section-components, .section-metrics, .section-scheduled {
        margin-top: 40px;
    }

    .navbar-custom {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        border-radius: 0px;
        margin-bottom: 10px;

        a.navbar-brand {
            font-size: 30px;
            font-weight: 600;
        }

        .navbar-nav li a {
            color: $cachet-base-dark;
            font-size: 1.7rem;

            &:hover {
                background-color: transparent;
            }
        }
    }
}
