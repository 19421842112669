label {
    font-size: 14px;
}

textarea {
    resize: none;
    overflow: auto;
}

.markdown-control {
    position: relative;

    &:before {
        position:absolute;
        display:block;
        right:0%;
        bottom:0%;
        width:40px;
        height:40px;
        font-size: 2em;
        font-family: "Ionicons";
        content: "\f4e6";
    }
}

.form-control {
    display: block;
    width: 100%;
    height: 45px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    @include box-shadow(none !important);
    @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

    &:focus {
        border-color: #66afe9;
    }
}

.component-inline {
    @media (max-width: $screen-xs-max) {
        .radio-items {
            text-align: left;
            .radio-inline {
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

.minicolors-theme-bootstrap .minicolors-swatch {
    top: 9px;
    left: 9px;
}
