@mixin pretty-buttons($color, $background, $text-shadow: none) {
    color: $color;
    background: $background;
    border: solid 1px darken($background, 10%);

    &:hover,
    &:focus,
    &:active,
    &.active {
        background: darken($background, 5%);
        border-color: darken($background, 10%);
        color: $color;
        outline: none;
        outline-offset: 0;
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &,
        &:hover,
        &:focus,
        &:active,
        &.active {
            background-color: lighten($background, 5%);
            border-color: darken($background, 10%);
        }
    }
}

.btn {
    &.btn-default {
        @include pretty-buttons($btn-default-color, $btn-default-bg);
    }
    &.btn-primary {
        @include pretty-buttons($btn-primary-color, $btn-primary-bg);
    }
    &.btn-success {
        @include pretty-buttons($btn-success-color, $btn-success-bg);
    }
    &.btn-info {
        @include pretty-buttons($btn-info-color, $btn-info-bg);
    }
    &.btn-warning {
        @include pretty-buttons($btn-warning-color, $btn-warning-bg);
    }
    &.btn-danger {
        @include pretty-buttons($btn-danger-color, $btn-danger-bg);
    }
    &.btn-inverse {
        @include pretty-buttons(white, #474949);
    }
}

.btn-outline {
    background-color: transparent;
    background-image: none !important;
    color: inherit;
    transition: all .5s;
}

.btn-default.btn-outline {
    color: #999;
}

.btn-primary.btn-outline {
    color: #428bca;
}

.btn-success.btn-outline {
    color: #5cb85c;
}

.btn-info.btn-outline {
    color: #5bc0de;
}

.btn-warning.btn-outline {
    color: #f0ad4e;
}

.btn-danger.btn-outline {
    color: #d9534f;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
    color: #fff;
}
