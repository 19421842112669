.uppercase {
    text-transform: uppercase;
}

.margin-top {
    margin-top: 20px;
}

.margin-bottom {
    margin-bottom: 20px;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
