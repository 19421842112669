// Brand colours.
$brand-primary:             darken(#78909c, 6.5%) !default;
$brand-success:             $cachet-primary !default;
$brand-info:                $cachet-blue !default;
$brand-warning:             $cachet-orange !default;
$brand-danger:              $cachet-red !default;

// Default border radius
$border-radius-base:        3px !default;
$border-radius-large:       4px !default;
$border-radius-small:       2px !default;

//** Tooltip background color
$tooltip-bg:                #333 !default;
$tooltip-opacity:           .9 !default;
$base-background-color:     #f1f1f1;

$base-font-family:          -apple-system, BlinkMacSystemFont, "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$base-font-weight:          400;
$base-letter-spacing:       0.08em;
$base-font-size:            15px;
$base-line-height:          1.42857143;

$base-link-color:           #ffffff;
$base-link-hover-color:     #e9e9e9;

$header-background-color:   lighten(#00695C, 10%);
$header-border-color:       1px solid darken($header-background-color, 10%);

$sidebar-transition-speed:  .2s;
$sidebar-background-color:  #78909c;
$sidebar-border-color:      1px solid #6c818c;
$sidebar-border-shadow:     inset 0px -2px 3px rgba(0,0,0,0.25);
$sidebar-text-size:         0.9em;
$sidebar-text-color:        #ffffff;
$sidebar-text-active-color: #ffffff;
$sidebar-normal-width:      235px;
$sidebar-phone-width:       75%;
$sidebar-active-color:      #00695C;
